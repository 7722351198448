;
jQuery.urp = {
    globalConfig: {
        multiselectConfig: {
            disableIfEmpty: true,
            enableClickableOptGroups: true,
            onChange: function (element, checked) {
            },
            buttonText: function (options, select) {
                if (options.length >= 1) {
                    var text = "";
                    if (options.length == $(select).find("option").length) {
                        text = '全部被选择';
                    }
                    else if (options.length >= 1 && options.length <= 2) {
                        jQuery.each(options, function (index, val) {
                            if (text)
                                text += ", ";
                            text += val.text;
                        });
                    }
                    else {
                        text = '%n项被选择'.replace("%n", options.length);
                    }
                    return text + ' <span><span></span></span>';
                } else {
                    return '请选择' + ' <b class="caret"></b>';
                }
            }
        },
        importErrorMessages: {"EMPTY": "必选项为空", "INVALID": "无效数据"}
    },
    showDialog: function (opts) {
        opts = $.extend({
            src: '',
            title: "",
            width: "100%",
            height: "450",
            init: "init data",
            close: function () {
            },
            ok: function (datas) {
            }
        }, opts);
        var dialog = $('#dialog');
        var frame = dialog.find('iframe');
        dialog.find(".modal-title").text(opts.title);

        frame.attr("width", opts.width).attr("height", opts.height).attr('src', opts.src)
            .attr("data-init", opts.init);
        dialog.modal('show');
        dialog.find('.dialog-close').one('click', function (e) {
            dialog.modal('hide');
            if (opts.close) {
                opts.close();
            }
        });

        dialog.find('.dialog-ok').one('click', function (e) {
            if (frame[0].contentWindow.exeDiaLogFun) {
                if (frame[0].contentWindow.exeDiaLogFun() === false)
                    return false;
            }
            dialog.modal('hide');
            if (opts.ok) {
                opts.ok(
                    JSON.parse(frame.contents().find('#JsonDatas').val())
                );
            }
        });
    },
    suggestUsers: function () {
        init();
        $('.wg-user-filter').on('keyup', function () {
            var target = $(this);
            var fullName = $(this).val();
            $.get($.urp.contextPath + '/user/suggest?full-name=' + fullName, function (data) {
                data = JSON.parse(data);
                updateTypeaheadSource(data, target);
            });
        });

        function init() {
            var val;
            $.each($("[data-userid]"), function () {
                var target = $(this).data("userid");
                val = $(this).val();
                if (val) {
                    $.get($.urp.contextPath + '/user/find?id=' + val, function (data) {
                        //data = JSON.parse(data);
                        $('[data-username="' + target + '"]').val(data["fullName"] + "(" + data["username"] + ")");
                    });
                }
            })
        }

        function updateTypeaheadSource(data, element) {
            var users = {};
            var userList = [];
            var key;
            $.each(data, function (i) {
                key = data[i]["fullName"] + "(" + data[i]["username"] + ")";
                users[key] = data[i]["id"];
                userList.push(data[i]["fullName"] + "(" + data[i]["username"] + ")");
            });
            element.data("typeahead").source = userList;
            var selected = setTargetValue(users, element);
            if (!selected) {
                var target = element.data("username");
                $('[data-userid="' + target + '"]').val(null);
            }
        }

        function setTargetValue(users, element) {
            var target = element.data("username");
            var selected = false;
            element.data("typeahead").updater = function (val) {
                $('[data-userid="' + target + '"]').val(users[val]);
                selected = true;
                return val;
            };
            return selected;
        }
    },
    post: function (url, datas) {
        var form = $("<form>")
            .attr("action", url)
            .attr("method", "post")
            .appendTo("body");
        $.each(datas, function (i, data) {
            $("<input type='hidden' name='" + data.name + "'>")
                .val(data.val)
                .appendTo(form);
        });
        form.submit();
    },
    getUrlParams: function () {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    },
    getUrlParam: function (name) {
        return $.urp.getUrlParams()[name];
    }
};

(function ($) {
    $.fn.initPost = function (url) {
        return this.each(function () {
            $(this).on("click", function () {
                var datas = [{name: "id", val: $(this).data('id')}];
                $.urp.post(url, datas);
            });
            return $(this);
        });
    };
    $.fn.initDel = function (url) {
        return this.each(function () {
            $(this).on("click", function () {
                var datas = [];
                datas.push({name: "id", val: $(this).data('id')});
                datas.push({name: "_method", val: "DELETE"});
                $.urp.post(url, datas);
            });
            return $(this);
        });
    };


})(jQuery);



