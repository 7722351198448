;
(function ($) {
    $.typeaheadValue = (function () {
        var init = function (source, idName, nameName) {
            var datas = source;
            var teach = _.find(datas, function (p) {
                return p.id == $('#' + idName).val();
            });
            if (teach) {
                $('#' + nameName).val(teach.name);
            }
            else {
                //$('#' + nameName).val("");
            }
            $('#' + nameName).typeahead({
                source: function (query, process) {
                    var ids = [];
                    $.each(datas, function (index, item) {
                        if ((item.name && item.name.indexOf(query) > -1) ||
                            (item.code && item.code.indexOf(query) > -1)) {
                            ids.push(item.id + '');
                        }
                    });
                    process(ids);
                },
                items: 8,
                matcher: function (item) {
                    return true;
                },
                highlighter: function (id) {
                    $('#' + idName).val("");
                    var product = _.find(datas, function (p) {
                        return p.id == id;
                    });
                    if (product.code == "")
                        return product.name;
                    else
                        return product.name + " (" + product.code + ")";
                },
                updater: function (id) {
                    var product = _.find(datas, function (p) {
                        return p.id == id;
                    });
                    var $ele = $('#' + idName);
                    $ele.val(id);
                    $ele.trigger('change');
                    return product.name;
                }
            });
        };
        return {
            init: init
        }
    })();
    $.typeaheads = (function () {
        var datas = [];
        var init = function (source, nameId) {
            datas = source;
            $('#' + nameId).typeahead({
                source: function (query, process) {
                    return datas;
                },
                highlighter: function (item) {
                    return item;
                },
                updater: function (item) {
                    return item;
                }
            });
        };
        return {
            init: init
        }
    })();
})(jQuery);
