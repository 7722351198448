;
(function ($) {
    $.datepicker = (function () {
        var init = function (callback) {
            var $datetime = $('input[data-type=date]');
            if ($datetime.length) {
                $datetime.datetimepicker({
                    format: $.urp.config.dateFormat,
                    showTodayButton: true,
                    locale: $('html').data('language')
                }).off('dp.change').on('dp.change', function () {
                    if (typeof callback === "function") {
                        callback();
                    }
                }).off('dp.hide').on('dp.hide', function () {
                    $(this).blur();
                }).inputmask($.urp.config.dateFormat.toLowerCase(), {
                    showMaskOnHover: false,
                    showMaskOnFocus: false
                });
            }

            $datetime = $('input[data-type=time]');
            if ($datetime.length) {
                $datetime.datetimepicker({
                    format: $.urp.config.shortTimeFormat,
                    stepping: 1,
                    defaultDate: moment(Date.now()),
                    locale: $('html').data('language')
                }).off('dp.change').on('dp.change', function () {
                    if (typeof callback === "function") {
                        callback();
                    }
                }).off('dp.hide').on('dp.hide', function () {
                    $(this).blur();
                }).inputmask($.urp.config.shortTimeFormat.toLowerCase(), {
                    showMaskOnHover: false,
                    showMaskOnFocus: false
                });
            }

            $datetime = $('input[data-type=date-time]');
            if ($datetime.length) {
                $datetime.datetimepicker({
                    format: $.urp.config.datetimeFormat,
                    sideBySide: true,
                    viewMode: 'days',
                    stepping: 15,
                    showTodayButton: true,
                    widgetPositioning: {horizontal: 'left', vertical: 'auto'},
                    locale: $('html').data('language')
                }).off('dp.change').on('dp.change', function () {
                    if (typeof callback === "function") {
                        callback($(this));
                    }
                }).off('dp.hide').on('dp.hide', function () {
                    $(this).blur();
                }).inputmask($.urp.config.datetimeFormat.toLowerCase(), {
                    showMaskOnHover: false,
                    showMaskOnFocus: false
                });
            }

            $datetime = $('input[data-type=date-time-any]');
            if ($datetime.length) {
                $datetime.datetimepicker({
                    format: $.urp.config.datetimeFormat,
                    sideBySide: true,
                    widgetPositioning: {horizontal: 'left', vertical: 'auto'},
                    locale: $('html').data('language')
                }).off('dp.hide').on('dp.hide', function () {
                    $(this).blur();
                }).off('dp.change').on('dp.change', function (e) {
                    if (typeof callback === "function") {
                        callback($(this));
                    }
                }).inputmask($.urp.config.datetimeFormat.toLowerCase(), {
                    showMaskOnHover: false,
                    showMaskOnFocus: false
                });
            }

        };

        $('body').on('click', '.wg-calendar', function () {
            $(this).siblings('[data-type]').trigger('focus');
        });

        return {
            init: function (callback) {
                init(callback);
            }
        };
    })();
})(jQuery);
