jQuery.message = (function () {
    var success = function (msg, callback) {
        $.growl(
            {message: msg},
            {type: 'success'}
        );
        if (callback) {
            setTimeout(callback, 1000);
        }
    }, error = function (msg) {
        $.growl(
            {message: msg},
            {type: 'danger'}
        );
    }, warn = function (msg) {
        $.growl(
            {message: msg},
            {type: 'warning'}
        );
    };

    return {
        success: success,
        error: error,
        warning: warn
    };
})();
