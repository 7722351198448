;(function ($) {
    $.fullScreen = (function () {
        var launchIntoFullScreen = function ($ele) {
            $ele.css("width", "100%");
            $ele.css("height", "100%");
            var element = $ele[0];
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        };
        return {
            launch: launchIntoFullScreen
        }
    })();
})(jQuery);