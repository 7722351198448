;
(function ($) {

    $.fn.extend({
        datetimepickerInstance: function () {
            return $(this).data("DateTimePicker");
        }
    });

    function convertToUtc($datetimePicker, isDatetime) {
        var $other = $datetimePicker.siblings('input');
        $other.val('');
        var date = $datetimePicker.datetimepickerInstance().date();
        if (date && date.isValid()) {
            if (isDatetime) {
                $other.val(date.utc().format());
            } else {
                $other.val(date.format($.urp.config.dateISO8601Format));
            }
        } else {
            $other.val("");
        }
    }

    function convertToLocalDatetime($datetimePicker) {
        var $datetimeInput = $datetimePicker.siblings('input');
        $datetimeInput.val('');
        var date = $datetimePicker.datetimepickerInstance().date();
        if (moment(date).isValid()) {
            $datetimeInput.val(date.utc().format());
        }
    }

    $.dateRange = (function () {
        var _option = {
            isDatetime: true,
            startCallback: function () {
            },
            endCallback: function () {
            }
        };
        var init = function ($datetimeStart, $datetimeEnd, option) {
            option = $.extend(_option, option);
            var datetimeFormat = $.urp.config.datetimeFormat;
            if (!option.isDatetime) {
                datetimeFormat = $.urp.config.dateFormat;
            }
            var $html = $('html');
            $datetimeStart.datetimepicker({
                format: datetimeFormat,
                sideBySide: true,
                widgetPositioning: {horizontal: 'left', vertical: 'auto'},
                locale: $html.data('language')
            });
            $datetimeEnd.datetimepicker({
                format: datetimeFormat,
                sideBySide: true,
                widgetPositioning: {horizontal: 'left', vertical: 'auto'},
                locale: $html.data('language'),
                useCurrent: false
            });
            $datetimeStart.on("dp.change", function (e) {
                $datetimeEnd.data("DateTimePicker").minDate(e.date);
                convertToUtc($(this), option.isDatetime);
                if (typeof option.startCallback === "function") {
                    option.startCallback();
                }
            }).inputmask(datetimeFormat.toLocaleLowerCase(), {
                showMaskOnHover: false,
                showMaskOnFocus: false
            });
            $datetimeEnd.on("dp.change", function (e) {
                $datetimeStart.data("DateTimePicker").maxDate(e.date);
                convertToUtc($(this), option.isDatetime);
                if (typeof option.startCallback === "function") {
                    option.endCallback();
                }
            }).inputmask(datetimeFormat.toLocaleLowerCase(), {
                showMaskOnHover: false,
                showMaskOnFocus: false
            });

            if (option.isDatetime) {
                convertToLocalDatetime($datetimeStart);
                convertToLocalDatetime($datetimeEnd);
            } else {
                convertToUtc($datetimeStart);
                convertToUtc($datetimeEnd);
            }
            if ($datetimeStart.val() != "" && $datetimeEnd.val() != "") {
                $datetimeStart.data("DateTimePicker").maxDate($datetimeEnd.data("DateTimePicker").date());
                $datetimeEnd.data("DateTimePicker").minDate($datetimeStart.data("DateTimePicker").date());
            }
        };
        return {
            init: init
        }
    })();

    $.selectDate = (function () {
        var init = function ($date, callback) {
            $date.datetimepicker({
                format: $.urp.config.dateFormat,
                showTodayButton: true,
                locale: $('html').data('language')
            }).off('dp.change').on('dp.change', function () {
                var $other = $(this).siblings('input');
                $other.val('');
                var date = $(this).datetimepickerInstance().date();
                if (date && date.isValid()) {
                    $other.val(date.format($.urp.config.dateISO8601Format));
                } else {
                    $other.val("");
                }
                if (typeof callback === "function") {
                    callback();
                }
            }).off('dp.hide').on('dp.hide', function () {
                $(this).blur();
            }).inputmask($.urp.config.dateFormat.toLowerCase(), {
                showMaskOnHover: false,
                showMaskOnFocus: false
            });

            var dateString = $date.siblings('input').val();
            if (dateString) {
                var date = moment(dateString, $.urp.config.dateISO8601Format);
                $date.datetimepickerInstance().date(moment(date).format($.urp.config.datetimeFormat));
            }
        };
        return {
            init: init
        }
    })();
})(jQuery);

